import {createContext, useEffect, useState} from 'react'
import Keycloak, {KeycloakConfig, KeycloakInitOptions} from 'keycloak-js';
import {axiosClient} from '@/utils/HttpClient';
import {Progress} from "@/components/ui/progress"
import Logo from "@/assets/img/elimu.png";
import Logo100x100 from "@/assets/img/logo_100x100.png";
import * as ProgressPrimitive from "@radix-ui/react-progress";


const MOBILE_SSO_CLIENT_ID = window.sessionStorage.getItem("MOBILE_WEBVIEW_SSO_CLIENT_ID") // will be provided in the context of mobile app webview
const REFRESH_TOKEN_BEFORE_SEC = 30; //30 sec prior to expiry date
const config: KeycloakConfig = {
    url: import.meta.env.VITE_SSO_HOST,
    realm: import.meta.env.VITE_SSO_REALM,
    clientId: MOBILE_SSO_CLIENT_ID || import.meta.env.VITE_SSO_CLIENT_ID,
}
const initOptions: KeycloakInitOptions = {
    onLoad: 'check-sso', // Supported values: 'check-sso' , 'login-required',
    //silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
    //checkLoginIframe: true,
    //checkLoginIframeInterval: 5,
    enableLogging: import.meta.env.VITE_SSO_CONSOLE_DEBUG,
    pkceMethod: 'S256',
    token: window.sessionStorage.getItem("MOBILE_WEBVIEW_ACCESS_TOKEN") as string, // will be provided in the context of mobile app webview
    refreshToken: window.sessionStorage.getItem("MOBILE_WEBVIEW_REFRESH_TOKEN") as string // will be provided in the context of mobile app webview
}

const keycloak = new Keycloak(config);

export const OAuthContext = createContext();
export let authenticate;
export const AuthContext = (props) => {

    const [ssoInitialized, setSSOInitialized] = useState(false)
    useEffect(() => {
        initKeycloak();
    }, [])

    useEffect(() => {
        let tokenUpdateInterval: any;
        if (keycloak.token) {
            // console.log("validity "+getTokenValidityInSec())
            tokenUpdateInterval = setInterval(async () => {
                keycloak.updateToken(REFRESH_TOKEN_BEFORE_SEC)
                    .then(refreshed => {
                        if (refreshed) {
                            addTokenToAxiosClientHeader()
                            //console.log('New token obtained');
                        } else {
                            // Token still valid
                            //console.log('Token refresh not needed');
                        }
                    })
                    .catch(error => {
                        //console.log('Failed to refresh token');
                        keycloak.logout();
                    });
            }, ((getTokenValidityInSec() - REFRESH_TOKEN_BEFORE_SEC) * 1000));
        }

        return () => clearInterval(tokenUpdateInterval);
    }, [keycloak.token]);

    const initKeycloak = () => {
        //console.log("keycloak initialization...");
        keycloak.init(initOptions).then((auth) => {
            if (!auth) {
                //window.location.reload();
            } else {
                addTokenToAxiosClientHeader()
            }
        }, () => {
            /* Notify the user if necessary */
            console.error("Authentication Failed");
        }).finally(() => setSSOInitialized(true))
    }

    const getTokenValidityInSec = (): number => {
        return keycloak.tokenParsed && keycloak.tokenParsed.exp
            ? keycloak.tokenParsed.exp - Math.floor(Date.now() / 1000)
            : 0;
    }

    const isAuthenticated = () => {
        return !!keycloak.token;
    }

    const addTokenToAxiosClientHeader = () => {
        axiosClient.defaults.headers[
            "Authorization"
            ] = `Bearer ${keycloak.token}`;
    }


    const getBearerToken = () => {
        return keycloak.token;
    }

    authenticate = () => {
        //console.log("authenticating ...");
        keycloak.login().then(() => {
            console.log("redirected");
            addTokenToAxiosClientHeader()
        })
    }
    const logout = () => {
        //console.log("logout ...");
        keycloak.logout().then(() => {
            //console.log("success");
        })
    }

    const hasAnyAuthority = (roles) => roles.some((role) => keycloak.hasRealmRole(role));
    const getUsername = () => keycloak.tokenParsed?.preferred_username;

    if (!ssoInitialized) {
        return <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div>
                <img src={Logo100x100} width={80} alt="Elimu logo"/>
                <div className="relative mt-4 mb-32 w-full h-1 bg-gray-200 overflow-hidden rounded">
                    <div className="absolute h-full w-2/3 bg-orange animate-slide"></div>
                </div>

            </div>
        </div>
            ;
    }
    return (
        <OAuthContext.Provider
            value={{
                ssoInitialized,
                getUsername,
                hasAnyAuthority,
                authenticate,
                getBearerToken,
                isAuthenticated,
                addTokenToAxiosClientHeader,
                logout
            }}
        >
            {props.children}
        </OAuthContext.Provider>
    );
}
