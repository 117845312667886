import '@vidstack/react/player/styles/base.css';

import {useContext, useEffect, useRef} from 'react';

import {
    isHLSProvider,
    MediaPlayer,
    MediaProvider,
    Poster,
    Track,
    type MediaCanPlayDetail,
    type MediaCanPlayEvent,
    type MediaPlayerInstance,
    type MediaProviderAdapter,
    type MediaProviderChangeEvent, useMediaStore, Spinner,
} from '@vidstack/react';

import {VideoLayout} from './components/layouts/video-layout';
import {textTracks} from './tracks';
import {handleHttpErrorResponseBody} from "@/utils/HttpClient";
import {OAuthContext} from "@/context/AuthContext.tsx";

export function Player({lesson}) {
    let player = useRef<MediaPlayerInstance>(null);
    const {getBearerToken} = useContext(OAuthContext);
    const mediaStore = useMediaStore(); // Access the media state


    useEffect(() => {
        // Subscribe to state updates.
        return player.current!.subscribe(({paused, viewType}) => {
            // console.log('is paused?', '->', state.paused);
            // console.log('is audio view?', '->', state.viewType === 'audio');
        });
    }, []);

    const BASE_URL = import.meta.env.VITE_BACKEND_SERVER;

    /**
     * update player request to add token in header
     * or handle http errors
     * @param adapter
     * @param event
     */
    const onProviderChange = (
        adapter: MediaProviderAdapter | null,
        event: MediaProviderChangeEvent
    ) => {
        const xhrSetup = (request: XMLHttpRequest, url: string) => {
            request.setRequestHeader("Authorization", "Bearer " + getBearerToken());
            request.withCredentials = true;
            request.addEventListener("readystatechange", () => {
                if (request.status === 401 || request.status === 403) {
                    handleHttpErrorResponseBody(JSON.parse(request?.response));
                }
            });
        };
        const provider = event.detail;
        if (isHLSProvider(provider)) {
            provider.config.xhrSetup = xhrSetup;
        }
    };

    // We can listen for the `can-play` event to be notified when the player is ready.
    function onCanPlay(detail: MediaCanPlayDetail, nativeEvent: MediaCanPlayEvent) {
        // ...
    }

    return (
        <MediaPlayer
            className="w-full lgh:max-h-[60vh] 2xl:min-h-[58vh] bg-slate-900 text-white font-sans overflow-hidden rounded-md ring-media-focus data-[focus]:ring-4"
            crossOrigin
            playsInline
            onProviderChange={onProviderChange}
            onCanPlay={onCanPlay}
            ref={player}
            title={lesson?.name as string}
            load="eager"
            autoPlay={false}
            src={{
                src: `${BASE_URL}/api/vod/${lesson?.video?.uuid}/playlist.m3u8`,
                type: "application/x-mpegurl",
            }}
        >
            <MediaProvider>
                <Poster
                    className="absolute inset-0 block h-full w-full rounded-md opacity-0 transition-opacity data-[visible]:opacity-100 object-cover"
                    src={`${BASE_URL}/content/${lesson.thumbnailFileName}`}
                    alt={lesson?.name as string}
                />
            </MediaProvider>
            <VideoLayout/>
        </MediaPlayer>
    );
}

