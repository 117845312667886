import {useContext, useState} from "react";
import {AvatarMenue} from "./AvatarMenu";
import {Link, useNavigate} from "react-router-dom";
import {DataContext} from "../../context/AppContext";
import Logo from "../../assets/img/elimu.png";
import LogoFavicon from "../../assets/img/elimu-favicon.png";


export const Header = () => {
    const [state, setState] = useState(false);
    const {selectedCategory, setSelectedCategory, mobileMenu, menu} =
        useContext(DataContext);

    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState<string>("");

    const searchQueryHandler = (event: any) => {
        if (
            (event?.key === "Enter" || event === "searchButton") &&
            searchQuery?.length > 0
        ) {
            navigate(`/cours?query=${searchQuery}`);
        }
    };
    const clickHandle = (item: any) => {
        setSelectedCategory(item.name);
        navigate(item.path);
    };

    return (
        <header className="text-base">
            <div
                className="bg-white items-center flex static"
            >

                <div
                    className="z-20 nav-menu flex-1 max-h-screen block overflow-visible"
                >
                    <ul className="items-center flex space-x-6 justify-between">
                        <Link to="/">
                            <img src={Logo} width={120} height={50} className={'hidden lg:block'} alt="Elimu logo"/>
                            <img src={LogoFavicon} width={50} height={50} className={'lg:hidden'} alt="Elimu logo"/>
                        </Link>
                        <form
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <div className="mt-5 lg:w-[600px] md:w-[540px] sm:w-[460px] xs:w-[200px]">
                                <div className="relative">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400 left-3"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                        />
                                    </svg>
                                    <input
                                        type="text"
                                        placeholder="Rechercher"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        onKeyUp={searchQueryHandler}
                                        className="w-full py-3 pl-12 pr-4 text-gray-500 border rounded-md outline-none bg-gray-50 focus:bg-white focus:border-orange"
                                    />
                                </div>
                            </div>
                        </form>
                        <AvatarMenue/>
                    </ul>
                </div>
            </div>
            <nav className="border-b mt-4">
                <ul className="flex items-center gap-x-3 mx-auto overflow-x-auto ">
                    {menu.map((item: any, idx: number) => {
                        return (
                            // Replace [idx == 0] with [window.location.pathname == item.path]
                            <li
                                key={idx}
                                className={`py-1 ${
                                    selectedCategory == item.name
                                        ? "border-b-2 border-orange"
                                        : ""
                                }`}
                            >
                                <a
                                    onClick={() => clickHandle(item)}
                                    href="#"
                                    className="lg:text-lg flex items-center gap-x-2 py-1.5 px-3 rounded-lg duration-150 group-hover:text-indigo-600 group-hover:bg-gray-50 group-active:bg-gray-100"
                                >
                                    {item.icon}
                                    {item.name}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </header>
    );
};
