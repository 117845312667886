import { Link } from "react-router-dom";
import VideoLength from "../shared/VideoLength";
import { CourseDTO } from "../generated";
import React from "react";
import { Badge } from "./ui/badge";
import dayjs from "dayjs";

const BASE_URL = import.meta.env.VITE_BACKEND_SERVER;

const CourseCard = (props: any) => {
  const course: CourseDTO = props.course;
  return (
    <Link to={`/cours/${course.uuid}`}>
      <article className="relative mx-auto my-4 shadow-lg border rounded-md duration-300 hover:shadow-sm">
       <div className="relative aspect-video bg-slate-500/[0.2]" >

          <img
          src={`${BASE_URL}/content/${course.thumbnailFileName}`}
          loading="lazy"
          alt={course.name as string}
          className="w-full h-30 rounded-t-md object-cover "
        />
                  <VideoLength time={course?.durationSec} />

       </div>
          <div className="flex items-center h-14 w-full px-3">
              <div className="min-w-8 hidden">
                  <img
                      src={"data:image/svg+xml;charset=utf-8," + course?.category?.icon}
                      className=" w-8 h-8 rounded-full"
                      alt="categorie"
                  />
              </div>
              <div className="flex items-center justify-between w-full">
                  <div className="px-2 text-md text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap">
                      {course?.category?.name} / {course.name}
                  </div>
                  <div className="">
                      <span
                          className="text-orange-800 bg-orange-100 text-md font-medium me-2 px-2.5 py-0.5 rounded">{course.level.name}</span>
                  </div>

              </div>
          </div>
      </article>

    </Link>
  );
};

export default CourseCard;
