import React from "react";
import { Link, Outlet } from "react-router-dom";

export const SettingsLayout = () => {
  const activeLinkClassName ='active text-orange bg-gray-100';
  const activeIconClassName ='text-orange';

  return (
    <>
      <div className="h-full pb-20">
        <section className="mt-12 mx-auto">
          <div className="md:flex">
            <ul className="flex-column space-y space-y-4 text-sm font-medium text-gray-500 md:me-4 mb-4 md:mb-0">

              <li>
                <Link
                  to="/parametres/abonnement"
                  className={activeLinkClassName+" inline-flex items-center px-4 py-3 text-[15px] rounded-md bg-gray-50 hover:bg-gray-200 w-full"}
                >
                  <svg
                    className={activeIconClassName+" w-4 h-4 me-2 text-gray-500"}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 18"
                  >
                    <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                  </svg>
                  Abonnement
                </Link>
              </li>
              <li>
                <a
                    href={`${import.meta.env.VITE_SSO_HOST}/realms/${import.meta.env.VITE_SSO_REALM}/account`}
                    className="inline-flex items-center px-4 py-3 text-[15px] rounded-lg  w-full bg-gray-50 hover:bg-gray-200 hover:text-gray-900"
                    aria-current="page"
                >
                  <svg
                      className="w-4 h-4 me-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                  >
                    <path
                        d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                  </svg>
                  Profile
                </a>
              </li>

            </ul>

            <div className="px-6 text-medium text-gray-500 rounded-lg w-full">


              <Outlet/>


            </div>
          </div>
        </section>
      </div>
    </>
  );
};
