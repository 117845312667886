import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import playTube from "../assets/img/taunel_192x86.png";
import playTubeMobile from "../assets/img/logo-colorfevicon.png";

import { SlMenu } from "react-icons/sl";
import { IoIosSearch } from "react-icons/io";
import { CgClose } from "react-icons/cg";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { HiOutlineStatusOffline } from "react-icons/hi";
import { MdOutlineLightMode } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";

import Loader from "../shared/Loader";
import UseOnline from "../utils/UseOnline";
import { AvatarMenue } from "./header/AvatarMenu";


const Header = () => {
  const [searchQuery, setSearchQuery] = useState("");


    const [ mobileMenu, setMobileMenu] = useState()
    const [ theme, setTheme ] = useState()


  const navigate = useNavigate();

  //custom hook for online/offline feature
  const isOnline = UseOnline();

  const searchQueryHandler = (event) => {
    if (
      (event?.key === "Enter" || event === "searchButton") &&
      searchQuery?.length > 0
    ) {
      navigate(`/searchResult/${searchQuery}`);
    }
  };
  const searchQueryHandler2 = () => {
    if (searchQuery?.length > 0) {
      navigate(`/searchResult/${searchQuery}`);
    }
  };

  const handleThemeSwitch = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  const mobileMenuToggle = () => {
    setMobileMenu(!mobileMenu);
  };

  //I don't want to show menu or leftNav for videoDetails page. for that reason i fetch the path name and use it wisely for video router.
  const { pathname } = useLocation();
  const pageName = pathname?.split("/")?.filter(Boolean)?.[0];

  return (
    <div className="sticky py-1.5 top-0 z-20 flex flex-row items-center justify-between h-14 px-4 md:px-5 bg-white dark:bg-black shadow-md">

      <div className="flex h-5 items-center">
        {pageName !== "video" && (
          <div
            onClick={mobileMenuToggle}
            className="flex md:hidden md:mr-6 mr-4 cursor-pointer items-center justify-center h-10 w-10 rounded-full hover:bg-[#8888]/[0.6]"
          >
            {mobileMenu ? (
              <CgClose className="text-black dark:text-white text-xl" />
            ) : (
              <SlMenu className="text-black dark:text-white text-xl" />
            )}
          </div>
        )}
        <Link to="/" className="flex h-5 items-center">
          <img
            src={playTube}
            alt="PlayTube"
            className="h-12 hidden md:block"
          />
          <img
            src={playTubeMobile}
            alt="PlayTube"
            className="h-8 w-8 md:hidden rounded-full"
          />
        </Link>
      </div>

      <div className="group flex items-center">
        <div className="rounded-l-lg flex h-8 md:h-10 md:ml-10 md:pl-5 border border-[#D4D4D4] group-focus-within:border-blue-500 md:group-focus-within:ml-5 md:group-focus-within:pl-0 ">
          <div className="w-10 justify-center items-center hidden group-focus-within:md:flex">
            <IoIosSearch className="text-black/[0.7] dark:text-white text-xl" />
          </div>
          <input
            type="text"
            value={searchQuery}
            placeholder="Search"
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyUp={searchQueryHandler}
            className="w-44 px-5 bg-transparent outline-none text-black dark:text-white md:pl-0 md:group-focus-within:pl-0 md:w-64 lg:w-[600px]"
          />
        </div>
        <button
          onClick={searchQueryHandler2}
          className="rounded-r-lg w-[40px] md:w-[60px] h-8 md:h-10 flex items-center justify-center border border-l-0 border-[#D4D4D4] bg-[#F8F8F8] dark:bg-white/[0.15]"
        >
          <IoIosSearch className="text-black/[0.9] dark:text-white text-xl" />
        </button>
      </div>

      <div className="flex items-center">
        <AvatarMenue/>
      </div>
    </div>
  );
};

export default Header;
