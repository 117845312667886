import { useState, useEffect, useContext } from "react";
import {axiosClient, handleHttpErrorResponseBody} from "@/utils/HttpClient";
import { useParams, useSearchParams } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiFillLike } from "react-icons/ai";
import { abbreviateNumber } from "js-abbreviation-number";
import { FaEye } from "react-icons/fa6";
import ShimmerSuggestionVideoCard from "../shared/ShimmerSuggestionVideoCard";
import LessonCard from "../components/LessonCard";
import { MENU_ICON_SIZE } from "../utils/constants";
import "./test.css";

import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import {
  MediaPlayer,
  MediaProvider,
  MediaProviderAdapter,
  MediaProviderChangeEvent,
  type PlyrLayoutTranslations,
  Poster,
  isHLSProvider, HLSManifestLoadedEvent, MediaErrorDetail, MediaErrorEvent, HLSManifestLoadingEvent,
} from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import {CourseChapterDTO, LessonDTO} from "../generated";
import { OAuthContext } from "../context/AuthContext.tsx";
import React from "react";
import {CourseChapterFilter} from "@/components/CourseChapterFilter";
import ViewCourseSkeletonLoader from "@/components/skeleton-loader/ViewCourseSkeletonLoader.tsx";
import {ManifestLoadedData, ManifestLoadingData} from "hls.js";
import {PlyrLayout} from "@vidstack/react/player/layouts/plyr";
import {Player} from "@/components/player/player.tsx";


const FRENCH: PlyrLayoutTranslations = {
  'Current time': '',
  'Disable captions': '',
  'Enable captions': '',
  'Enter Fullscreen': '',
  'Enter PiP': '',
  'Exit Fullscreen': '',
  'Exit PiP': '',
  'Go back to previous menu': '',
  'Ad': '',
  'AirPlay': '',
  'All': '',
  'Audio': '',
  'Auto': '',
  'Buffered': '',
  'Captions': '',
  'Default': '',
  'Disabled': '',
  'Download': '',
  'Duration': '',
  'Enabled': '',
  'End': '',
  'Forward': '',
  'LIVE': '',
  'Loop': '',
  'Mute': '',
  'Normal': '',
  'Pause': '',
  'Play': '',
  'Played': '',
  'Quality': 'Qualité',
  'Reset': '',
  'Restart': 'Reprendre',
  'Rewind': '',
  'Seek': '',
  'Settings': 'Paramètres',
  'Speed': 'Vitesse',
  'Start': '',
  'Unmute': '',
  'Volume': '',
};

const ViewCourse = () => {
  const { courseUUID } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const lessonUUID = searchParams?.get("lessonUUID");
  const [lessons, setLessons] = useState<Array<LessonDTO>>([]);
  const [selectedLesson, setSelectedLesson] = useState<LessonDTO>();
  const [selectedChapter, setSelectedChapter] = useState<CourseChapterDTO>()
  const [availableChapters, setAvailableChapters] = useState<CourseChapterDTO[]>([])
  const [loading, setLoading] = useState(true);
  const { getBearerToken } = useContext(OAuthContext);

  const [videoKey, setVideoKey] = useState<string>("")

  useEffect(() => {
    document?.getElementById("root")?.classList.add("custom-h");
    fetchCourseChaptersAndLessons(courseUUID as string);
  }, [courseUUID]);

  useEffect(() => {
    if (selectedChapter){
      const lessonsFromChapterUUID = getLessonsFromChapterUUID(selectedChapter.uuid);
      setLessons(lessonsFromChapterUUID);
      setSelectedLesson(lessonsFromChapterUUID[0]);
    }else{
      const lessonsFromChapters = getLessonsFromChapters(availableChapters);
      setLessons(lessonsFromChapters);
      setSelectedLesson(
          lessonUUID ? findLessonByLessonUUID(lessonUUID, lessonsFromChapters) : lessonsFromChapters[0]
      );
    }
  }, [selectedChapter]);

  /**
   * return lessons for a given chapter
   * @param chapterUUID
   */
  const getLessonsFromChapterUUID = (chapterUUID : string) : Array<LessonDTO> =>{
      return  availableChapters.find(chapter => chapter.uuid === chapterUUID)?.lessons;
  }

  const getLessonsFromChapters = (courseChapters : CourseChapterDTO[] ) : Array<LessonDTO> =>{
      return Array.from(courseChapters.flatMap(chapter => chapter.lessons));
  }

  const fetchCourseChaptersAndLessons =  (courseUUID: string) => {
    setLoading(true);
    axiosClient(`/course-chapters?courseUUID.equals=${courseUUID}`).then(res => {
       if (res.data) {
         setAvailableChapters(res.data);
         const lessonsFromChapters = getLessonsFromChapters(res.data);
         setLessons(lessonsFromChapters);
         setSelectedLesson(
             lessonUUID ? findLessonByLessonUUID(lessonUUID, lessonsFromChapters) : lessonsFromChapters[0]
         );
       }
     }).finally(()=>{
      setLoading(false);
    })

  };

  const findLessonByLessonUUID = (
    lessonUUID: string,
    lessonArray: LessonDTO[]
  ): LessonDTO | undefined => {
    return lessonArray.find((l) => l.uuid == lessonUUID);
  };

  const selectLesson = (lesson: LessonDTO) => {
    setSelectedLesson(findLessonByLessonUUID(lesson.uuid, lessons));
    setSearchParams({ lessonUUID: lesson.uuid });
  };
  const BASE_URL = import.meta.env.VITE_BACKEND_SERVER;

  /**
   * update player request to add token in header
   * or handle http errors
   * @param adapter
   * @param event
   */
  const onProviderChange = (
    adapter: MediaProviderAdapter | null,
    event: MediaProviderChangeEvent
  ) => {
    const xhrSetup = (request: XMLHttpRequest, url: string) => {
      request.setRequestHeader("Authorization", "Bearer " + getBearerToken());
      request.withCredentials = true;
      request.addEventListener("readystatechange", () => {
        if (request.status===401 || request.status===403){
          handleHttpErrorResponseBody(JSON.parse(request?.response));
        }
      });
    };
    const provider = event.detail;
    if (isHLSProvider(provider)) {
      provider.config.xhrSetup = xhrSetup;
    }
  };



  return (loading ? <ViewCourseSkeletonLoader/> :
    <div className="w-full max-w-[1500px] flex flex-col lg:flex-row ">
      {selectedLesson && (
          <div className="flex flex-col lg:w-[calc(100%-350px)] xl:w-[calc(100%-400px)] mx-3 py-2  overflow-y-auto">
           <Player lesson={selectedLesson} />


            <div className="text-black dark:text-white font-bold text-sm md:text-xl mt-4 line-clamp-2">
              {availableChapters[0]?.course.category.name} / {availableChapters[0]?.course.name}
            </div>
            <div className="text-black dark:text-white text-xs md:text-lg mt-2 line-clamp-2">
              {selectedLesson?.name}
            </div>

{/*
            <div className=" flex justify-between flex-col md:flex-row mt-4">
              <div className="flex">
                <div className="flex items-start">
                  <div className="flex h-11 w-11 rounded-full overflow-hidden">
                    <img
                        width={MENU_ICON_SIZE}
                        src={
                            "data:image/svg+xml;charset=utf-8," +
                            (selectedLesson?.course?.category?.icon as string)
                        }
                        alt="Avatar"
                        className="h-full w-full object-cover"
                    />
                  </div>
                </div>

                <div className="flex flex-col ml-3">
                  <div className="text-black dark:text-white text-base font-semibold flex items-center">
                    {selectedLesson?.name}

                    <BsFillCheckCircleFill className="text-black/[0.7] dark:text-white/[0.5] text-[12px] ml-1"/>
                  </div>

                  <div className="text-black/[0.7] dark:text-white/[0.7] text-sm">
                    {selectedLesson?.viewCount}
                  </div>
                </div>
              </div>

              <div className="flex text-black dark:text-white mt-4 md:mt-0">
                <div
                    className="flex items-center justify-center h-11 px-6 rounded-3xl bg-black/[0.15] dark:bg-white/[0.15]">
                  <AiFillLike className="text-xl text-black dark:text-white mr-2"/>
                  <span>{`${abbreviateNumber(
                      selectedLesson?.viewCount as number,
                      2
                  )}`}</span>
                </div>
                <div
                    className="flex items-center justify-center h-11 px-6 rounded-3xl bg-black/[0.15] dark:bg-white/[0.15] ml-4">
                  <FaEye className="text-xl text-black dark:text-white mr-2"/>
                  <span>{`${abbreviateNumber(
                      selectedLesson?.viewCount as number,
                      2
                  )}`}</span>
                </div>
              </div>
            </div>
*/}
          </div>
      )}

      <div className="flex flex-col py-2 mx-3 overflow-y-auto lg:w-[350px] xl:w-[400px]">
        <div className="mb-4">
          <CourseChapterFilter onSelectCourseChapter={(chapter : CourseChapterDTO) => setSelectedChapter(chapter)} availableCourseChapters={availableChapters}  />
        </div>

        {lessons?.map((item, index) => {
              return (
                <div
                    key={index}
                  className="cursor-pointer"
                  onClick={() => selectLesson(item)}
                >
                  {" "}
                  <LessonCard key={index} lesson={item} />
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default ViewCourse;
