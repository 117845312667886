/* tslint:disable */
/* eslint-disable */
/**
 * Elimu API
 * Elimu API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { MembershipPlanDTO } from './membership-plan-dto';
// May contain unused imports in some cases
// @ts-ignore
import type { VoucherDTO } from './voucher-dto';

/**
 * 
 * @export
 * @interface MembershipDTO
 */
export interface MembershipDTO {
    /**
     * 
     * @type {number}
     * @memberof MembershipDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'paymentServerRequest'?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'paymentServerResponse'?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'paymentRequestSubmittedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'paymentRequestCancelledAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'paymentResquestcompletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'paymentRequestStatus': MembershipDTOPaymentRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'paymentTransactionRef': string;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'paymentGatewayTransactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'paymentMobileNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'paymentMethod': MembershipDTOPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'membershipStartAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'membershipEndAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MembershipDTO
     */
    'validityInMonths': number;
    /**
     * 
     * @type {number}
     * @memberof MembershipDTO
     */
    'totalPrice': number;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'currency': MembershipDTOCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'lastModifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {VoucherDTO}
     * @memberof MembershipDTO
     */
    'voucher'?: VoucherDTO;
    /**
     * 
     * @type {string}
     * @memberof MembershipDTO
     */
    'userId'?: string;
    /**
     * 
     * @type {MembershipPlanDTO}
     * @memberof MembershipDTO
     */
    'plan': MembershipPlanDTO;
}

export const MembershipDTOPaymentRequestStatusEnum = {
    Submitted: 'SUBMITTED',
    Completed: 'COMPLETED',
    Cancelled: 'CANCELLED'
} as const;

export type MembershipDTOPaymentRequestStatusEnum = typeof MembershipDTOPaymentRequestStatusEnum[keyof typeof MembershipDTOPaymentRequestStatusEnum];
export const MembershipDTOPaymentMethodEnum = {
    Cash: 'CASH',
    Mpesa: 'MPESA',
    AirtelMoney: 'AIRTEL_MONEY',
    OrangeMoney: 'ORANGE_MONEY',
    BankDeposit: 'BANK_DEPOSIT',
    Voucher: 'VOUCHER'
} as const;

export type MembershipDTOPaymentMethodEnum = typeof MembershipDTOPaymentMethodEnum[keyof typeof MembershipDTOPaymentMethodEnum];
export const MembershipDTOCurrencyEnum = {
    Usd: 'USD',
    Cdf: 'CDF'
} as const;

export type MembershipDTOCurrencyEnum = typeof MembershipDTOCurrencyEnum[keyof typeof MembershipDTOCurrencyEnum];


