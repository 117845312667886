import { useContext, useEffect } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { OAuthContext } from "../context/AuthContext.tsx";
import AppContext from "../context/AppContext";

const ProtectedLayout = () => {
  //let [searchParams, setSearchParams] = useSearchParams();
  //const accessToken = searchParams?.get("accessToken");
  //console.log('accessToken=',accessToken);

  const {
    isAuthenticated,
    authenticate,
    addTokenToAxiosClientHeader,
  } = useContext(OAuthContext);

  useEffect(() => {
    if (isAuthenticated()) {
      addTokenToAxiosClientHeader();
    } else {
      authenticate();
    }
  }, []);
  return (
    isAuthenticated() && (
      <AppContext>
        <Outlet />
      </AppContext>
    )
  );
};

export default ProtectedLayout;
