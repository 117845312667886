import type {ReactElement} from 'react';

import {
    Menu,
    Tooltip,
    type MenuPlacement,
    type TooltipPlacement, useVideoQualityOptions,
} from '@vidstack/react';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    SettingsIcon,
    SettingsMenuIcon,
} from '@vidstack/react/icons';

import {buttonClass, tooltipClass} from './buttons';
import {CheckIcon} from "lucide-react";

export interface SettingsProps {
    placement: MenuPlacement;
    tooltipPlacement: TooltipPlacement;
}

export const submenuClass =
    'hidden w-full flex-col items-start justify-center outline-none data-[keyboard]:mt-[3px] data-[open]:inline-block';

export function Settings({placement, tooltipPlacement}: SettingsProps) {
    return (
        <Menu.Root className="parent sm:hidden">
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    <Menu.Button className={buttonClass+' hidden sm:flex '}>
                        <SettingsIcon
                            className="h-8 w-8 transform transition-transform duration-200 ease-out group-data-[open]:rotate-90"/>
                    </Menu.Button>
                </Tooltip.Trigger>
                <Tooltip.Content className={tooltipClass} placement={tooltipPlacement}>
                    Settings
                </Tooltip.Content>
            </Tooltip.Root>
            <Menu.Content className="vds-settings-menu-items vds-menu-items" placement={placement}>
                <QualitySubmenu/>
            </Menu.Content>
        </Menu.Root>
    );
}

function QualitySubmenu() {
    const options = useVideoQualityOptions(),
        currentQuality = options.selectedQuality?.height,
        hint =
            options.selectedValue !== 'auto' && currentQuality
                ? `${currentQuality}p`
                : `Auto${currentQuality ? ` (${currentQuality}p)` : ''}`;
    return (
        <Menu.Root>
            <SubmenuButton
                label="Quality"
                hint={hint}
                disabled={options.disabled}
                icon={SettingsMenuIcon}
            />
            <Menu.Content className={submenuClass}>
                <Menu.RadioGroup className="w-full flex flex-col" value={options.selectedValue}>
                    {options.map(({label, value, bitrateText, select}) => (
                        <Menu.Radio className="vds-radio" value={value} onSelect={select} key={value}>
                            <CheckIcon size={18} className={`opacity-${options.selectedValue === value ?'100':'0'} mr-2`}/>
                            <span className="vds-radio-label">{label}</span>
                            {bitrateText ? <span className="vds-radio-hint">{bitrateText}</span> : null}
                        </Menu.Radio>
                    ))}
                </Menu.RadioGroup>
            </Menu.Content>
        </Menu.Root>
    );
}

export interface SubmenuButtonProps {
    label: string;
    hint: string;
    disabled?: boolean;
    icon: ReactElement;
}

function SubmenuButton({label, hint, icon: Icon, disabled}: SubmenuButtonProps) {
    return (
        <Menu.Button
            className="ring-media-focus parent left-0 z-10 flex w-full cursor-pointer select-none items-center justify-start rounded-sm bg-black/60 p-2.5 outline-none ring-inset data-[open]:sticky data-[open]:-top-2.5 data-[hocus]:bg-white/10 data-[focus]:ring-[3px] aria-disabled:hidden"
            disabled={disabled}
        >
            <ChevronLeftIcon className="parent-data-[open]:block -ml-0.5 mr-1.5 hidden h-[18px] w-[18px]"/>
            <div className="contents parent-data-[open]:hidden">
                <Icon className="w-5 h-5"/>
            </div>
            <span className="ml-1.5 parent-data-[open]:ml-0">{label}</span>
            <span className="ml-auto text-sm text-white/50">{hint}</span>
            <ChevronRightIcon className="parent-data-[open]:hidden ml-0.5 h-[18px] w-[18px] text-sm text-white/50"/>
        </Menu.Button>
    );
}
